import { Link, StaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';
import style from './header.module.scss';
import { Container } from './container';
import { Button } from './button';
import { InfoIcon } from './icons/info';
import { PkLogo } from './icons/pk-logo';
import { WsLogo } from './icons/ws-logo';
import { UserContext } from '../context/UserContext';
import { API_ENDPOINT } from '../api';
import { BlameIcon } from './icons/blame-icon';

const HeaderImage = () => (
  <StaticQuery
    query={graphql`
      query {
        mainLogo: file(relativePath: { eq: "ws_logo.png" }) {
          childImageSharp {
            fluid(maxWidth: 180) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => (
      <Img
        fluid={data.mainLogo.childImageSharp.fluid}
        loading="eager"
        placeholderClassName={style.imagePlaceholder}
        fadeIn={false}
        style={{ marginTop: '8px' }}
      />
    )}
  />
);

const Header = ({ siteTitle }) => (
  <header className={style.header}>
    <Container>
      <div className={style.image}>
        <div className={style.svgWrap}>
          <a href="https://www.plankooperativ.de" target="_blank">
            <PkLogo />
          </a>
          <a href="https://www.webschuppen.com" target="_blank">
            <WsLogo style={{ height: '17px' }} />
          </a>
        </div>
      </div>
      <div className={style.title}>
        <h1 style={{ margin: 0, padding: 0 }}>
          <Link to="/">
            <img
              src={require('../images/brauchtdich-org_logo.png')}
              alt="brauchtdich.org"
              style={{ height: '44px', top: 2, width: 'auto', position: 'relative', margin: 0 }}
            />
          </Link>
        </h1>
      </div>
      <div className={style.pullRight}>
        <UserContext.Consumer>
          {user =>
            user.loggedIn &&
            user.roles.includes('ROLE_ADMIN') && (
              <a href={`${API_ENDPOINT}/api/export/csv`} download>
                <Button modifier={'light'}>Export CSV</Button>
              </a>
            )
          }
        </UserContext.Consumer>
      </div>
    </Container>
  </header>
);

export default Header;
